
  export const style = {
    api: {
      apiProduction: "https://grupopardini.api.appnoz.com.br/",
      newApiProduction: "null",
      samlUrl: "https://sam-grupopardini.appnoz.com.br/auth/login/grupopardini",
      nameProfile: "grupopardini",
      hostHeader: "null",
    },

    icons: {
      loginIcon: "https://timcoo-geral.s3.amazonaws.com/Logos/new_logo_grupopardini.png",
      menuIcon: localStorage.getItem("menuIcon") || "https://timcoo-geral.s3.amazonaws.com/Logos/secundary_logo_hmsp.png",
      browserLogo: "https://timcoo-geral.s3.amazonaws.com/Logos/new_browser_logo_pardini2.png",
    },

    shareAttributes: {
      shareImage: "https://timcoo-geral.s3.amazonaws.com/Logos/new_logo_grupopardini.png",
      shareTitle: "Noz - Plataforma de comunicação",
      shareDescription: "O NOZ é um aplicativo de comunicação personalizado, onde é possível criar conteúdos de acordo com a segmentação do seu público de maneira gamificada.",
    },

    names: {
      nameApp: localStorage.getItem("nameApp") || "grupopardini",
      nameScore: "Nozes",
      nameStore: "null",
      nameAcademy: localStorage.getItem("nameAcademy") || "Academy",
      nameTeam: localStorage.getItem("nameTeam") || "Time",
      nameCalendar: localStorage.getItem("nameCalendar") || "Calendário",
      nameTools: localStorage.getItem("nameTools") || "Dia a Dia",
    },

    colors: {
      primaryColor: localStorage.getItem("primaryColor") || "#B10034",
      secondaryColor: localStorage.getItem("secondaryColor") || "#CE003C",
      tertiaryColor: "",
    },

    variables: {
      gamificationEnabled: false,
      child_profiles: ["ECOAR","CENTRO DE MEDICINA","SOLUÇÃO","PROGENÉTICA","DLE RJ","DLE SP","TOXICOLOGIA SP","TOXICOLOGIA MG","LABCLASS","DIAGNÓSTIKA SP","DIAGNÓSTIKA RJ","PADRÃO","LPA","APC","ODIVÂNIA","IACS","CSV","HP SP","HP MG"],
      hasPrivacyPolicies: false,
      externalUse: false,
      customClient: false,
    },
  };
